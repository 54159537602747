import { Box, Divider, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { lockStatuses } from "../utils/constants";
import { TakeMockButton } from "./utils/buttons";
import { LockUnlockIcon } from "./utils/icons";

export default function TopicCard({
  id,
  title,
  description,
  thumbnail,
  color,
  primaryColor,
  secondaryColor,
  onLearn,
  status = lockStatuses.LOCKED,
}) {
  return (
    <Card
      variant="outlined"
      sx={{
        margin: 1,
        minWidth: 280,
        height: 460,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        backgroundColor: color,
        "&:hover": {
          borderColor: secondaryColor,
        },
      }}
    >
      <CardMedia
        component="img"
        height="260"
        src={thumbnail}
        alt={title}
        onClick={onLearn}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
          backgroundColor: "#fff",
        }}
      />
      <CardContent
        onClick={onLearn}
        sx={{
          backgroundColor: color,
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Divider sx={{ ml: "auto", mr: 1 }} orientation="vertical" flexItem />
          <Tooltip
            title={
              status === lockStatuses.UNLOCKED ? (
                <span>
                  You have unlocked this course.
                  <br />
                  Start learning now!
                </span>
              ) : status === lockStatuses.FINISHED ? (
                <span>
                  You have completed this course.
                  <br />
                  You can take the mock test now!
                </span>
              ) : (
                <span>
                  This course is locked.
                  <br />
                  Please complete the previous course to unlock this course.
                </span>
              )
            }
          >
            <LockUnlockIcon status={status} />
          </Tooltip>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ marginTop: "auto", backgroundColor: "#fff" }}>
        <Button size="small" sx={{ color: primaryColor }} onClick={onLearn}>
          Learn
        </Button>
        <TakeMockButton
          hideBorder
          topicTitle={title}
          topicId={id}
          primaryColor={secondaryColor}
        />
      </CardActions>
    </Card>
  );
}
