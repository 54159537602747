import DoneAllIcon from "@mui/icons-material/DoneAll";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import { blue, green } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPreparationPlanTests } from "../api/content";
import routes from "../layout/routes";
import {
  PREPARATION_PLAN_MODES,
  PREPARATION_PRACTICE_TEST_PROGRESS_CUTOFF,
  lockStatuses,
} from "../utils/constants";
import SubTopicCard from "./SubTopicCard";
import PreparationPlanTestCard from "./cards/PreparationPlanTestCard";

const PreparationPlan = ({ preparationPlan, progress = {}, isCurrent }) => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [daysFinishedStatus, setDaysFinishedStatus] = useState([]);
  const [tests, setTests] = useState({});
  const navigate = useNavigate();
  const dayRefs = useRef([]);

  const openSubTopic = (topicId, subTopicId) => {
    navigate(routes.subTopicDetail.getPath(topicId, subTopicId));
  };

  useEffect(() => {
    init();
  }, [preparationPlan]);

  const init = async () => {
    if (!preparationPlan?._id) return;
    if (!isCurrent) return;
    try {
      const { tests } = await getPreparationPlanTests(preparationPlan._id);
      const testsMap = tests.reduce((acc, test) => {
        const key = test.preparationPlanDetails.dayItemId;
        if (!acc[key]) acc[key] = [];
        acc[key].push(test);
        return acc;
      }, {});
      setTests(testsMap);

      const daysFinishedStatus = preparationPlan.schedule.map((dayItem) =>
        dayItem.mode === PREPARATION_PLAN_MODES.STUDY ||
        dayItem.mode === PREPARATION_PLAN_MODES.REVISION
          ? dayItem.subTopics.every(
              (subTopic) =>
                progress[subTopic._id]?.status === lockStatuses.FINISHED
            )
          : dayItem.mode === PREPARATION_PLAN_MODES.PRACTICE
          ? testsMap[dayItem._id]?.some((test) =>
              test?.score?.total > 0
                ? test?.score?.score / test?.score?.total >
                  PREPARATION_PRACTICE_TEST_PROGRESS_CUTOFF
                : false
            )
          : null
      );
      setDaysFinishedStatus(daysFinishedStatus);
      const currentDay = daysFinishedStatus.findIndex((day) => !day);
      setSelectedDay(currentDay);
      dayRefs.current[currentDay]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return preparationPlan.schedule?.map((dayItem, index) => {
    const testUnlocked = preparationPlan.schedule[index - 1]?.subTopics.every(
      (subTopic) => progress[subTopic._id]?.status === lockStatuses.FINISHED
    );

    return (
      <Accordion
        expanded={selectedDay === index}
        ref={(el) => (dayRefs.current[index] = el)}
        onChange={() => setSelectedDay(selectedDay === index ? -1 : index)}
        key={dayItem._id}
        sx={{
          backgroundColor: daysFinishedStatus[index]
            ? green[50]
            : "theme.palette.background.paper",
        }}
      >
        <AccordionSummary
          expandIcon={
            isCurrent && selectedDay === null ? (
              <CircularProgress size={20} />
            ) : daysFinishedStatus[index] ? (
              <DoneAllIcon color="success" />
            ) : (
              <ExpandMoreIcon />
            )
          }
          aria-controls={`panel-content-${index}`}
          id={`panel-header-${index}`}
        >
          Day {index + 1}
        </AccordionSummary>
        <AccordionDetails>
          {dayItem.mode === PREPARATION_PLAN_MODES.STUDY ||
          dayItem.mode === PREPARATION_PLAN_MODES.REVISION ? (
            dayItem.subTopics.map((subTopic) => (
              <SubTopicCard
                key={subTopic._id}
                title={subTopic.title}
                status={progress[subTopic._id]?.status}
                primaryColor={blue[700]}
                color={blue[50]}
                openSubTopic={() =>
                  openSubTopic(subTopic.topic.title, subTopic.title)
                }
              />
            ))
          ) : (
            <PreparationPlanTestCard
              preparationPlanId={preparationPlan._id}
              dayItem={dayItem}
              dayNumber={index + 1}
              testUnlocked={testUnlocked}
              tests={tests[dayItem._id]}
            />
          )}
        </AccordionDetails>
      </Accordion>
    );
  });
};

export default PreparationPlan;
